<template>
    <v-container fluid>
        <v-toolbar color="primary" dark>
            <v-text-field
                class="mx-15 mb-n5"

                v-model="params.query"
                clearable
                label="Buscar"
            />

            <v-spacer/>

            <v-btn color="primary" @click="descarga">
                <v-icon left>mdi-file-excel</v-icon>
                Descargar
            </v-btn>
        </v-toolbar>

        <v-data-table
            elevation="2"
            :headers="headers"
            disable-sort
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
            }"

            item-key="id"
            :loading="loadingMeds"

            :server-items-length='total'
            :options.sync="optionsTable"

            class="elevation-1"

            :items="meds"
            disable-pagination
            disable-filtering

        >
            <template v-slot:[`item.especificacion`]="{ item }">
                <span v-html='item.especificacion'/>
            </template>
            <template v-slot:[`item.observaciones`]="{ item }">
                <span v-html='item.observaciones'/>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                    icon
                    @click="muestraCaducidad(item)"
                >
                    <v-icon>mdi-view-list</v-icon>
                </v-btn>
            </template>

        </v-data-table>


        <v-dialog
            v-model="dialogCaducidad"
            width="650"
        >
            <v-card>
                <v-toolbar color="primary" dark dense>
                    <v-toolbar-title>Existencias/caducidades</v-toolbar-title>
                    <v-spacer/>
                    <v-toolbar-items>
                        <v-btn @click="dialogCaducidad=false" icon><v-icon>mdi-close-box</v-icon></v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>

                    <div class="pa-5">
                        <div class="text-h6">{{esteMed.clave}} - {{esteMed.concepto}}</div>
                        <span>{{esteMed.especificacion}}</span>
                    </div>

                    <v-skeleton-loader
                        v-if="loadingCads"
                        type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line"
                    />

                    <v-alert type="warning" text v-if="caducidades.length==0 && !loadingCads">
                        No hay registros disponibles para esta clave.
                    </v-alert>

                    <v-sheet class="elevation-2" v-if="caducidades.length > 0">
                        <v-list three-line dense>
                            <v-list-item  v-for="itm in caducidades" :key="itm.id">
                                    <v-list-item-icon>
                                        <div>Existe<br/><strong>{{itm.disponible}}</strong></div>

                                    </v-list-item-icon>

                                    <v-list-item-content>

                                        <v-list-item-title>
                                            <div v-if="itm.nombreComercial">{{itm.nombreComercial}} ({{itm.laboratorio}})</div>
                                            <div>Lote: {{itm.lote}}</div>
                                        </v-list-item-title>

                                        <v-list-item-subtitle>
                                            Recibido por {{itm.recibe}} el {{itm.fechaRecepcion}}
                                        </v-list-item-subtitle>

                                        <v-list-item-subtitle>
                                            <span style="white-space: pre-wrap;" v-html="itm.observaciones"/>
                                        </v-list-item-subtitle>

                                        <v-divider/>

                                    </v-list-item-content>


                                    <v-list-item-action>
                                        <v-chip
                                            :color="calculaCad(itm.caducidad).color"
                                        >
                                            <span class="white--text">{{itm.caducidad}}</span>
                                        </v-chip>
                                        {{calculaCad(itm.caducidad).text}}
                                    </v-list-item-action>

                            </v-list-item>
                        </v-list>

                    </v-sheet>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    data:()=>({

        dialogCaducidad:false,

        params:{},

        headers:[
            {text:'Clave', value:'clave', width:'5%',},
            {text:'Concepto', value:'concepto', width:'10%'},
            {text:'Especificacion', value:'especificacion', width:'10%'},
            {text:'Existencia', value:'existenciaActual', width:'5%', align:'center'},
            {text:'Observaciones', value:'observaciones', width:'10%',},
            {text:'Cad.', value:'actions', width:'5%', align:'center',},
        ],
        meds:[],
        loadingMeds:false,
        esteMed:{},
        caducidades:[],
        caducidadesTotal:0,
        loadingCads:false,
        
        optionsTable:{},
        total:0,

        busca:{},
    }),

    methods:{

        calculaCad(cad){
            let color = 'white'

            let cd = this.moment(this.moment('01/'+cad).format('DD/MM/YYYY'))
            let fin = this.moment(cd).add(1, 'M').subtract(1, 'd')
            let hoy = this.moment(new Date())
            //let seisMeses = this.moment(new Date()).add(6, 'm')
            //let unAnio = this.moment(new Date()).add(1, 'a')
            let falta = fin.diff(hoy, 'months')
            let text = hoy.to(fin)
            if (falta <0){
                color='black'
                text='Caducado'
            }
            if (falta >=0 && falta <=6){
                color = 'red'
            }
            if (falta >6 && falta <=12){
                color = 'orange'
            }
            if (falta > 12){
                color = 'green'
                text = 'mas de un año'
            }
            return {color:color, text:text}
        },

        async descarga(){
            try{
                await this.$http({
                    url: '/comi/imprimeExistencias',
                    method: 'GET',
                    responseType: 'arraybuffer',
                }).then((response)=>{
                    //console.log(response)
                    var headers = response.headers;
                    var blob = new Blob([response.data],{type:headers['content-type']});
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "Existencias-"+this.moment(new Date()).format('DD-MM-YYYY')
                    link.click();
                })
                
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        async list(){
            this.loadingMeds=true
            
                this.params.tipoInsumo=1
                this.params.start=(this.optionsTable.page-1 )*this.optionsTable.itemsPerPage
                this.params.limit=this.optionsTable.itemsPerPage
            try{
                let eva = await this.$http({
                    url: '/comi/listMedicamentoCb',
                    method:'GET',
                    params: this.params,
                })
                this.loadingMeds=false
                this.meds = eva.data.root
                this.total = eva.data.total
            }catch(err){
                this.loadingMeds=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        
         listCaducidades(){
            //this.loadingMeds=true
            
        },
        async muestraCaducidad(med){

            this.dialogCaducidad=true
            this.caducidades=[]
            this.esteMed = med
            this.loadingCads=true
            try{
                let eva = await this.$http({
                    url: '/comi/listLoteMedicamento',
                    method:'GET',
                    params: {medicamento:med.id},
                })

                this.loadingCads=false
                this.caducidades = eva.data.root
                this.caducidadesTotal = eva.total
                
            }catch(err){
                this.loadingCads=false
                this.$store.dispatch('security/revisaError',err)
            }
        }
    },
    watch:{
        optionsTable: {
            handler () {
                //console.log(this.optionsTable)
                this.list()
            },
            deep: true,
        },
        'params.query'(v){
            if ((v && v.length >=3 && !this.loadingMeds) || !v){
                    this.optionsTable.page = 1,
                    this.list()
            }
        },
    },
}
</script>

<style>

</style>